import Navbar from "./components/NavbarComp"
import Home from './pages/home';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {

    return (

      <Home />,
      <Navbar/>
  
    );
  }

export default App;
